import React from 'react';
import { theme } from './Theme';
import { timelineItems, journeySection } from '../data/formationData';
import { useTranslation } from '../contexts/LanguageContext';

const JourneySection = () => {
  const { translate } = useTranslation();
  const sectionTexts = translate(journeySection);

  return (
    <section 
      className="py-24"
      style={{ backgroundColor: theme.colors.background.primary }}
    >
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 
            className="text-3xl md:text-4xl font-bold mb-4"
            style={{ color: theme.colors.text.primary }}
          >
            {sectionTexts.title}
          </h2>
          <p 
            className="max-w-2xl mx-auto"
            style={{ color: theme.colors.text.secondary }}
          >
            {sectionTexts.subtitle}
          </p>
        </div>

        <div className="relative">
          {/* Ligne verticale de la timeline */}
          <div 
            className="absolute left-4 md:left-1/2 h-full w-0.5 transform -translate-x-1/2"
            style={{ backgroundColor: theme.colors.border.light }}
          />

          {/* Items de la timeline */}
          <div className="space-y-12">
            {timelineItems.map((item, index) => {
              const itemTexts = translate(item);
              
              return (
                <div 
                  key={index} 
                  className={`relative flex items-center ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}
                >
                  {/* Point sur la timeline */}
                  <div 
                    className="absolute left-4 md:left-1/2 w-4 h-4 rounded-full transform -translate-x-1/2"
                    style={{ backgroundColor: theme.colors.primary }}
                  >
                    <div 
                      className="absolute w-8 h-8 rounded-full border-2 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
                      style={{ 
                        borderColor: theme.colors.primary,
                        opacity: 0.3
                      }}
                    />
                  </div>

                  {/* Contenu */}
                  <div className={`ml-12 md:ml-0 ${index % 2 === 0 ? 'md:pr-12 md:w-1/2' : 'md:pl-12 md:w-1/2'}`}>
                    <div 
                      className="p-6 rounded-xl hover:shadow-md transition-shadow"
                      style={{ 
                        backgroundColor: theme.colors.background.card,
                        border: `1px solid ${theme.colors.border.light}`,
                        ':hover': {
                          boxShadow: theme.shadows.md,
                          borderColor: theme.colors.border.dark
                        }
                      }}
                    >
                      <div className="flex items-center gap-3 mb-3">
                        <div 
                          className="p-2 rounded-lg"
                          style={{ 
                            backgroundColor: `${theme.colors.primary}15`,
                            color: theme.colors.primary 
                          }}
                        >
                          {item.icon}
                        </div>
                        <span 
                          className="text-sm font-medium"
                          style={{ color: theme.colors.text.secondary }}
                        >
                          {item.date}
                        </span>
                      </div>
                      <h3 
                        className="text-xl font-semibold mb-1"
                        style={{ color: theme.colors.text.primary }}
                      >
                        {itemTexts.title}
                      </h3>
                      <h4 
                        className="text-sm font-medium mb-3"
                        style={{ color: theme.colors.text.secondary }}
                      >
                        {itemTexts.subtitle}
                      </h4>
                      <p 
                        style={{ color: theme.colors.text.light }}
                      >
                        {itemTexts.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default JourneySection;