import React, { useState } from 'react';
import { Globe, Check } from 'lucide-react';
import { useTranslation } from '../contexts/LanguageContext';
import { theme } from '../components/Theme';

const LanguageSelector = () => {
  const { currentLanguage, setCurrentLanguage } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'fr', label: 'Français', flag: '🇫🇷' },
    { code: 'en', label: 'English', flag: '🇬🇧' }
  ];

  return (
    <div className="relative z-50"> {/* Augmentation du z-index */}
      {/* Bouton principal */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300 hover:scale-105"
        style={{ 
          backgroundColor: theme.colors.background.card,
          border: `1px solid ${theme.colors.border.light}`,
          color: theme.colors.text.primary,
        }}
      >
        <Globe size={18} className="text-current" />
        <span className="font-medium">
          {languages.find(lang => lang.code === currentLanguage)?.flag}
          {' '}
          {currentLanguage.toUpperCase()}
        </span>
      </button>

      {/* Menu déroulant */}
      <div
        className={`absolute right-0 mt-2 w-48 rounded-lg shadow-lg transition-all duration-300 transform origin-top z-50 ${
          isOpen 
            ? 'opacity-100 scale-100 translate-y-0' 
            : 'opacity-0 scale-95 -translate-y-2 pointer-events-none'
        }`}
        style={{ 
          backgroundColor: theme.colors.background.card,
          border: `1px solid ${theme.colors.border.light}`,
        }}
      >
        <div className="py-1">
          {languages.map((language) => (
            <button
              key={language.code}
              onClick={() => {
                setCurrentLanguage(language.code);
                setIsOpen(false);
              }}
              className="w-full flex items-center justify-between px-4 py-2 transition-colors hover:opacity-80"
              style={{ 
                color: theme.colors.text.primary,
                backgroundColor: currentLanguage === language.code 
                  ? `${theme.colors.primary}15`
                  : 'transparent'
              }}
            >
              <div className="flex items-center gap-3">
                <span className="text-xl">{language.flag}</span>
                <span className="font-medium">{language.label}</span>
              </div>
              {currentLanguage === language.code && (
                <Check size={16} style={{ color: theme.colors.primary }} />
              )}
            </button>
          ))}
        </div>
      </div>

      {/* Overlay pour fermer le menu en cliquant à l'extérieur */}
      {isOpen && (
        <div
          className="fixed inset-0 h-full w-full cursor-default z-40"
          onClick={() => setIsOpen(false)}
          style={{ backgroundColor: 'transparent' }}
        />
      )}
    </div>
  );
};

export default LanguageSelector;