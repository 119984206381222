import React from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import ProjectSection from "../components/ProjectSection";
import SkillsSection from "../components/SkillSection";
import JourneySection from "../components/JourneySection"; // Ajoutez cette ligne
import Navbar from "../components/Navbar";
import { LanguageProvider } from "../contexts/LanguageContext";

const Home = () => {
  return (
    <div className="min-h-screen ">
      <LanguageProvider>
        <Navbar />
        <Hero />
        <JourneySection /> 
        <SkillsSection />
        <ProjectSection/>
        <Footer />
      </LanguageProvider>
    </div>
  );
};

export default Home;