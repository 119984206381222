import React from 'react';
import { skillCategories, skillsSection } from '../data/skillData';
import { Star } from 'lucide-react';
import { theme } from './Theme';
import { useTranslation } from '../contexts/LanguageContext';

const SkillsSection = () => {
  const { translate } = useTranslation();
  const sectionTexts = translate(skillsSection);

  const getLevelColor = (level) => {
    switch (level) {
      case "Advanced":
        return {
          background: `${theme.colors.primary}20`,
          color: theme.colors.primary
        };
      case "Intermediate":
        return {
          background: `${theme.colors.secondary}30`,
          color: theme.colors.secondary
        };
      default:
        return {
          background: `${theme.colors.accent}20`,
          color: theme.colors.accent
        };
    }
  };

  const SkillCard = ({ name, logo, level, translations }) => {
    const skillTexts = translate({ translations });
  
    const getStarsCount = (level) => {
      switch (level) {
        case "Advanced":
        case "Expert":
          return 3;
        case "Intermediate":
          return 2;
        case "Basic":
        default:
          return 1;
      }
    };
  
    const getEmptyStars = (level) => {
      const totalStars = 3;
      const filledStars = getStarsCount(level);
      return totalStars - filledStars;
    };
  
    return (
      <div 
        className="group p-6 rounded-xl border transition-all duration-300 hover:shadow-lg"
        style={{ 
          backgroundColor: theme.colors.background.card,
          borderColor: theme.colors.border.light,
          ':hover': {
            borderColor: theme.colors.border.dark,
            boxShadow: theme.shadows.md
          }
        }}
      >
        <div className="flex items-center space-x-4">
          <div className="relative">
            <div 
              className="absolute inset-0 rounded-full transform -rotate-6 transition-transform group-hover:rotate-0"
              style={{ backgroundColor: `${theme.colors.accent}15` }}
            />
            <div 
              className="relative w-14 h-14 flex items-center justify-center rounded-full border transition-colors"
              style={{ 
                backgroundColor: theme.colors.background.card,
                borderColor: theme.colors.border.light,
                ':hover': { borderColor: theme.colors.border.dark }
              }}
            >
              <img 
                src={logo} 
                alt={`${name} logo`} 
                className="w-8 h-8 object-contain group-hover:scale-110 transition-transform duration-300"
              />
            </div>
          </div>
          
          <div className="flex-1">
            <h4 
              className="text-lg font-semibold mb-2"
              style={{ color: theme.colors.text.primary }}
            >
              {name}
            </h4>
            <div className="flex items-center gap-3">
              <span 
                className="px-3 py-1 rounded-full text-xs font-medium"
                style={getLevelColor(level)}
              >
                {level}
              </span>
              <span 
                className="text-xs"
                style={{ color: theme.colors.text.secondary }}
              >
                {skillTexts.experience}
              </span>
            </div>
          </div>
  
          {/* Stars Rating */}
          <div className="flex items-center gap-0.5">
            {/* Filled Stars */}
            {[...Array(getStarsCount(level))].map((_, i) => (
              <Star
                key={`filled-${i}`}
                size={16}
                className="fill-current"
                style={{ color: theme.colors.primary }}
              />
            ))}
            {/* Empty Stars */}
            {[...Array(getEmptyStars(level))].map((_, i) => (
              <Star
                key={`empty-${i}`}
                size={16}
                style={{ 
                  color: theme.colors.border.light,
                  opacity: 0.5 
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const CategorySection = ({ icon, skills, translations }) => {
    const categoryTexts = translate({ translations });

    return (
      <div className="mb-16">
        <div className="mb-8">
          <div className="flex items-center gap-3 mb-3">
            <div style={{ color: theme.colors.accent }}>
              {icon}
            </div>
            <h3 
              className="text-2xl font-bold"
              style={{ color: theme.colors.text.primary }}
            >
              {categoryTexts.title}
            </h3>
          </div>
          <p 
            className="ml-9"
            style={{ color: theme.colors.text.secondary }}
          >
            {categoryTexts.description}
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {skills.map((skill, index) => (
            <SkillCard key={index} {...skill} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <section 
      className="py-24"
      style={{ backgroundColor: theme.colors.background.primary }}
    >
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 
            className="text-3xl md:text-4xl font-bold mb-4"
            style={{ color: theme.colors.text.primary }}
          >
            {sectionTexts.title}
          </h2>
          <p 
            className="max-w-2xl mx-auto"
            style={{ color: theme.colors.text.secondary }}
          >
            {sectionTexts.subtitle}
          </p>
        </div>
        <div className="space-y-20">
          {skillCategories.map((category, index) => (
            <CategorySection key={index} {...category} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;