import React, { useState, useEffect } from 'react';
import { 
  Github, 
  Mail, 
  Linkedin, 
  Menu, 
  X,
  Download
} from 'lucide-react';
import { theme } from './Theme';
import LanguageSelector from '../contexts/LanguageSelector';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header 
      className={`fixed w-full z-50 transition-all duration-300`}
      style={{
        backgroundColor: isScrolled ? `${theme.colors.background.primary}CC` : 'transparent',
        backdropFilter: isScrolled ? 'blur(8px)' : 'none',
        boxShadow: isScrolled ? theme.shadows.sm : 'none',
        padding: isScrolled ? '1rem 0' : '1.5rem 0'
      }}
    >
      <nav className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          {/* Logo/Nom */}
          <a 
            href="/" 
            className="text-xl font-bold"
            style={{ color: theme.colors.text.primary }}
          >
            El Amrani
          </a>

          {/* Navigation Desktop */}
          <div className="hidden md:flex items-center gap-8">
            {/* Liens sociaux et actions */}
            <LanguageSelector />  {/* Ajoutez ici */}
            <div className="flex items-center gap-4">
              <a 
                href="https://github.com/elmrnidev" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-2 rounded-full transition-colors"
                style={{ 
                  color: theme.colors.text.primary,
                  ":hover": { backgroundColor: theme.colors.background.secondary }
                }}
                aria-label="GitHub Profile"
              >
                <Github size={20} />
              </a>
              
              <a 
                href="https://www.linkedin.com/in/el-amrani-mounir/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-2 rounded-full transition-colors"
                style={{ 
                  color: theme.colors.text.primary,
                  ":hover": { backgroundColor: theme.colors.background.secondary }
                }}
                aria-label="LinkedIn Profile"
              >
                <Linkedin size={20} />
              </a>
              
              <a 
                href="mailto:your@email.com"
                className="p-2 rounded-full transition-colors"
                style={{ 
                  color: theme.colors.text.primary,
                  ":hover": { backgroundColor: theme.colors.background.secondary }
                }}
                aria-label="Send Email"
              >
                <Mail size={20} />
              </a>

              <a 
                href="/cv.pdf" 
                className="flex items-center gap-2 px-4 py-2 rounded-lg transition-colors"
                style={{ 
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.background.primary,
                  ":hover": { backgroundColor: theme.colors.hover.primary }
                }}
              >
                <Download size={18} />
                <span className="font-medium">CV</span>
              </a>
            </div>
          </div>

          {/* Menu Mobile */}
          <button 
            className="md:hidden p-2 rounded-lg"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={{ color: theme.colors.text.primary }}
            aria-label="Toggle Menu"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Menu Mobile Déroulant */}
        {isMenuOpen && (
          <div 
            className="md:hidden absolute top-full left-0 right-0 py-4"
            style={{ 
              backgroundColor: theme.colors.background.primary,
              borderTop: `1px solid ${theme.colors.border.light}`,
              boxShadow: theme.shadows.md 
            }}
          >
            <div className="container mx-auto px-4 flex flex-col gap-4">
              <a 
                href="#services" 
                className="py-2 transition-colors"
                style={{ 
                  color: theme.colors.text.secondary,
                  ":hover": { color: theme.colors.text.primary }
                }}
              >
                Services
              </a>
              <a 
                href="#projets" 
                className="py-2 transition-colors"
                style={{ 
                  color: theme.colors.text.secondary,
                  ":hover": { color: theme.colors.text.primary }
                }}
              >
                Projets
              </a>
              <a 
                href="#contact" 
                className="py-2 transition-colors"
                style={{ 
                  color: theme.colors.text.secondary,
                  ":hover": { color: theme.colors.text.primary }
                }}
              >
                Contact
              </a>
              
              <div 
                className="h-px my-2" 
                style={{ backgroundColor: theme.colors.border.light }}
              />
              
              <div className="flex gap-4">
                <a 
                  href="https://github.com/elmrnidev" 
                  className="p-2 rounded-lg transition-colors"
                  style={{ 
                    color: theme.colors.text.primary,
                    ":hover": { backgroundColor: theme.colors.background.secondary }
                  }}
                >
                  <Github size={20} />
                </a>
                <a 
                  href="https://www.linkedin.com/in/el-amrani-mounir/" 
                  className="p-2 rounded-lg transition-colors"
                  style={{ 
                    color: theme.colors.text.primary,
                    ":hover": { backgroundColor: theme.colors.background.secondary }
                  }}
                >
                  <Linkedin size={20} />
                </a>
                <a 
                  href="mailto:elamrani.mounir.1999@gmail.com" 
                  className="p-2 rounded-lg transition-colors"
                  style={{ 
                    color: theme.colors.text.primary,
                    ":hover": { backgroundColor: theme.colors.background.secondary }
                  }}
                >
                  <Mail size={20} />
                </a>
              </div>
              
              <a 
                href="/cv.pdf" 
                className="flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors"
                style={{ 
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.background.primary,
                  ":hover": { backgroundColor: theme.colors.hover.primary }
                }}
              >
                <Download size={18} />
                <span>Télécharger CV</span>
              </a>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;