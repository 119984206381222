// data/personalData.jsx
export const heroConfig = {
  name: "El Amrani Mounir",
  cv : "/cv.pdf",
  social: {
    github: "https://github.com/elmrnidev",
    linkedin: "https://www.linkedin.com/in/el-amrani-mounir/"
  },
  translations: {
    fr: {
      greeting: "Bonjour, je suis",
      title: "Développeur",
      description: "Passionné par le développement et l'automatisation de tâches.",
      contact: "Me Contacter",
      downloadCV: "CV"
    },
    en: {
      greeting: "Hello, I am",
      title: "Developer",
      description: "Passionate about development and task automation.",
      contact: "Contact Me",
      downloadCV: "Resume"
    }
  }
};