import React from 'react';
import { GraduationCap, Briefcase } from 'lucide-react';

export const timelineItems = [
    {
      type: 'education',
      date: '2019 - 2024',
      icon: <GraduationCap size={20} />,
      translations: {
        fr: {
          title: 'Bachelor en Informatique',
          subtitle: 'HE2B-ESI',
          description: 'Spécialisation en Réseaux et Télécommunications. Formation approfondie en développement, systèmes et réseaux.'
        },
        en: {
          title: 'Bachelor in Computer Science',
          subtitle: 'HE2B-ESI',
          description: 'Specialization in Networks and Telecommunications. Advanced training in development, systems and networks.'
        }
      }
    },
    {
      type: 'work',
      date: '09/2023 - 01/2024',
      icon: <Briefcase size={20} />,
      translations: {
        fr: {
          title: 'Cloud Engineer',
          subtitle: 'LiiA Technologies',
          description: 'Développement et implémentation de solutions cloud privées. Mise en place d\'infrastructures réseau, automatisation avec Terraform et gestion de la sécurité.'
        },
        en: {
          title: 'Cloud Engineer',
          subtitle: 'LiiA Technologies',
          description: 'Development and implementation of private cloud solutions. Network infrastructure setup, automation with Terraform and security management.'
        }
      }
    },
    {
      type: 'education',
      date: '2019 - 2020',
      icon: <GraduationCap size={20} />,
      translations: {
        fr: {
          title: 'Formation Web Developer',
          subtitle: 'Institut Paul Hankar',
          description: 'Formation intensive en développement web, couvrant les technologies frontend et backend.'
        },
        en: {
          title: 'Web Developer Training',
          subtitle: 'Paul Hankar Institute',
          description: 'Intensive web development training, covering frontend and backend technologies.'
        }
      }
    },
    {
      type: 'work',
      date: '06/2019 - 07/2019',
      icon: <Briefcase size={20} />,
      translations: {
        fr: {
          title: 'Technicien Réparation Mobile',
          subtitle: 'BelMobile',
          description: 'Diagnostic et réparation de smartphones, service client et gestion des demandes de réparation.'
        },
        en: {
          title: 'Mobile Repair Technician',
          subtitle: 'BelMobile',
          description: 'Smartphone diagnostics and repair, customer service and repair request management.'
        }
      }
    },
    {
      type: 'education',
      date: '2016 - 2018',
      icon: <GraduationCap size={20} />,
      translations: {
        fr: {
          title: 'CESS Technique Informatique',
          subtitle: 'Institut Don Bosco',
          description: 'Formation technique en informatique, bases fondamentales de la programmation et des systèmes.'
        },
        en: {
          title: 'Technical Computer Science Diploma',
          subtitle: 'Don Bosco Institute',
          description: 'Technical computer training, fundamental basics of programming and systems.'
        }
      }
    }
];

// Traductions pour la section
export const journeySection = {
  translations: {
    fr: {
      title: "Mon Parcours",
      subtitle: "Un parcours orienté vers l'expertise technique et l'innovation, combinant formation académique et expérience pratique."
    },
    en: {
      title: "My Journey",
      subtitle: "A journey focused on technical expertise and innovation, combining academic training and practical experience."
    }
  }
};