import React from 'react';
import { Github, LinkedinIcon } from 'lucide-react';
import { theme } from './Theme';

const Footer = () => {
  return (
    <footer className="relative">
      
      
      <div
        className="w-full"
        style={{ 
          backgroundColor: theme.colors.background.primary,
        }}
      >
        <div className="container mx-auto py-4 px-4">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            {/* Social Links */}
            <div className="flex items-center gap-6">
              <a 
                href="https://github.com" 
                className="transition-all duration-300 hover:scale-110"
                style={{ 
                  color: theme.colors.text.primary
                }}
                aria-label="GitHub"
              >
                <div className="p-2 rounded-full hover:bg-opacity-10"
                  style={{
                    backgroundColor: `${theme.colors.primary}10`,
                  }}>
                  <Github size={24} />
                </div>
              </a>
              <a 
                href="https://linkedin.com" 
                className="transition-all duration-300 hover:scale-110"
                style={{ 
                  color: theme.colors.text.primary
                }}
                aria-label="LinkedIn"
              >
                <div className="p-2 rounded-full hover:bg-opacity-10"
                  style={{
                    backgroundColor: `${theme.colors.primary}10`,
                  }}>
                  <LinkedinIcon size={24} />
                </div>
              </a>
            </div>

            {/* Copyright Text */}
            <div 
              className="flex flex-col items-center md:items-end gap-2"
            >
              <p 
                className="text-center md:text-right text-sm font-medium"
                style={{ 
                  color: theme.colors.text.secondary
                }}
              >
                © 2024 El Amrani. Tous droits réservés.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;