import React from 'react';
import { Github, ExternalLink, FolderGit2 } from 'lucide-react';
import { theme } from './Theme';
import { useTranslation } from '../contexts/LanguageContext';
import { projectsData, projectsSection } from '../data/projectsData';

const ProjectCard = ({ project }) => {
  const { translate } = useTranslation();
  const texts = translate({ translations: project.translations });

  return (
    <div 
      className="group rounded-xl overflow-hidden transition-all duration-300"
      style={{ 
        backgroundColor: theme.colors.background.card,
        border: `1px solid ${theme.colors.border.light}`,
        ':hover': {
          boxShadow: theme.shadows.lg,
          borderColor: theme.colors.border.dark
        }
      }}
    >
      {/* Image du projet avec overlay au hover */}
      <div className="relative aspect-[16/9] w-full overflow-hidden">
        <img 
          src={project.imageUrl} 
          alt={`${texts.title} preview`} 
          className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
        />
        <div 
          className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center gap-4"
          style={{ 
            backgroundColor: theme.colors.background.overlay
          }}
        >
          {project.githubUrl && (
            <a 
              href={project.githubUrl}
              target="_blank"
              rel="noopener noreferrer" 
              className="p-2 rounded-full transition-colors"
              style={{ 
                backgroundColor: theme.colors.background.card,
                color: theme.colors.text.primary,
                ':hover': {
                  backgroundColor: theme.colors.background.secondary
                }
              }}
              aria-label="Voir le code source"
            >
              <Github size={24} />
            </a>
          )}
          {project.demoUrl && (
            <a 
              href={project.demoUrl}
              target="_blank"
              rel="noopener noreferrer" 
              className="p-2 rounded-full transition-colors"
              style={{ 
                backgroundColor: theme.colors.background.card,
                color: theme.colors.text.primary,
                ':hover': {
                  backgroundColor: theme.colors.background.secondary
                }
              }}
              aria-label="Voir la démo"
            >
              <ExternalLink size={24} />
            </a>
          )}
        </div>
      </div>
      
      {/* Contenu */}
      <div className="p-6">
        <div 
          className="flex items-center gap-2 mb-4"
          style={{ color: theme.colors.accent }}
        >
          <FolderGit2 size={20} />
          <span 
            className="text-sm font-medium"
            style={{ color: theme.colors.text.secondary }}
          >
            Project
          </span>
        </div>

        <h3 
          className="text-xl font-semibold mb-2"
          style={{ color: theme.colors.text.primary }}
        >
          {texts.title}
        </h3>
        
        <p 
          className="mb-6 text-sm leading-relaxed"
          style={{ color: theme.colors.text.secondary }}
        >
          {texts.description}
        </p>
        
        <div className="flex flex-wrap gap-2">
          {project.technologies.map((tech, techIndex) => (
            <span 
              key={techIndex} 
              className="px-3 py-1 text-xs font-medium rounded-full transition-colors"
              style={{ 
                backgroundColor: `${theme.colors.primary}15`,
                color: theme.colors.primary,
                ':hover': {
                  backgroundColor: `${theme.colors.primary}25`
                }
              }}
            >
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const ProjectSection = () => {
  const { translate } = useTranslation();
  const sectionTexts = translate(projectsSection);

  return (
    <section 
      className="py-16" 
      id="projects"
      style={{ 
        background: theme.colors.background.primary
      }}
    >
      <div className="max-w-6xl mx-auto px-4">
        {/* En-tête de la section */}
        <div className="text-center mb-12">
          <h2 
            className="text-3xl font-bold mb-4"
            style={{ color: theme.colors.text.primary }}
          >
            {sectionTexts.title}
          </h2>
          <p 
            className="max-w-2xl mx-auto text-lg"
            style={{ color: theme.colors.text.secondary }}
          >
            {sectionTexts.subtitle}
          </p>
        </div>

        {/* Grille des projets */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projectsData.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;