// Theme.jsx
export const theme1 = {
  colors: {
    primary: '#666666',    // Gris moyen
    secondary: '#999999',  // Gris clair
    accent: '#333333',     // Gris foncé
    dark: '#1A1A1A',       // Presque noir
    text: {
      primary: '#1A1A1A',   // Presque noir
      secondary: '#666666', // Gris moyen
      light: '#999999'      // Gris clair
    },
    background: {
      primary: '#FFFFFF',   // Blanc
      secondary: '#F5F5F5', // Gris très clair
      tertiary: '#EEEEEE',  // Gris très clair
      card: '#FFFFFF',      // Blanc
      overlay: 'rgba(0, 0, 0, 0.1)' // Noir transparent
    },
    border: {
      light: '#EEEEEE',     // Gris très clair
      dark: '#666666'       // Gris moyen
    },
    hover: {
      primary: '#999999',   // Gris clair
      secondary: '#666666', // Gris moyen
      dark: '#333333'       // Gris foncé
    }
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)'
  },
  gradients: {
    primary: 'linear-gradient(to right, #666666, #999999)',
    secondary: 'linear-gradient(to right, #999999, #333333)'
  }
};

export const theme2 = {
  colors: {
    primary: '#D4B483',    // Beige doré
    secondary: '#C19A6B',  // Brun doré
    accent: '#9C7A4B',     // Brun moyen
    dark: '#6B4423',       // Brun foncé
    text: {
      primary: '#4A2F17',   // Brun très foncé
      secondary: '#6B4423', // Brun foncé
      light: ''      // Beige doré
    },
    background: {
      primary: '#FDF6E9',   // Beige très clair
      secondary: '#F5E6D3', // Beige clair
      tertiary: '#E6D5BE',  // Beige moyen
      card: '#FFFFFF',      // Blanc
      overlay: 'rgba(107, 68, 35, 0.2)' // Brun transparent
    },
    border: {
      light: '#E6D5BE',     // Beige moyen
      dark: '#C19A6B'       // Brun doré
    },
    hover: {
      primary: '#C19A6B',   // Brun doré
      secondary: '#9C7A4B', // Brun moyen
      dark: '#6B4423'       // Brun foncé
    }
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(107, 68, 35, 0.05)',
    md: '0 4px 6px -1px rgba(107, 68, 35, 0.1)',
    lg: '0 10px 15px -3px rgba(107, 68, 35, 0.1)'
  },
  gradients: {
    primary: 'linear-gradient(to right, #D4B483, #C19A6B)',
    secondary: 'linear-gradient(to right, #C19A6B, #9C7A4B)'
  }
};

export const theme3 = {
  colors: {
    primary: '#BB86FC',    // Violet clair
    secondary: '#985EF7',  // Violet
    accent: '#6200EE',     // Violet foncé
    dark: '#121212',       // Noir profond
    text: {
      primary: '#FFFFFF',   // Blanc
      secondary: '#E0E0E0', // Gris très clair
      light: '#BBBBBB'      // Gris clair
    },
    background: {
      primary: '#121212',   // Noir profond
      secondary: '#1E1E1E', // Noir moins profond
      tertiary: '#2C2C2C',  // Gris très foncé
      card: '#2C2C2C',      // Gris très foncé
      overlay: 'rgba(0, 0, 0, 0.5)' // Noir transparent
    },
    border: {
      light: '#2C2C2C',     // Gris très foncé
      dark: '#3D3D3D'       // Gris foncé
    },
    hover: {
      primary: '#985EF7',   // Violet
      secondary: '#6200EE', // Violet foncé
      dark: '#3700B3'       // Violet très foncé
    }
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.9)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.9)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.9)'
  },
  gradients: {
    primary: 'linear-gradient(to right, #BB86FC, #985EF7)',
    secondary: 'linear-gradient(to right, #985EF7, #6200EE)'
  }
};

export const theme4 = {
  colors: {
    primary: '',    // Vert forêt foncé
    secondary: '',  // Brun bois
    accent: '',     // Vert olive
    dark: '',       // Terre de Sienne
    text: {
      primary: '',   // Vert forêt foncé
      secondary: '', // Brun bois
      light: ''      // Or foncé
    },
    background: {
      primary: '',   // Beige clair
      secondary: '', // Brun clair
      tertiary: '',  // Brun moyen
      card: '',      // Beige très clair
      overlay: '' // Vert forêt transparent
    },
    border: {
      light: '',     // Brun clair
      dark: ''       // Brun bois
    },
    hover: {
      primary: '',   // Vert forêt plus clair
      secondary: '', // Brun bois plus clair
      dark: ''       // Terre de Sienne plus claire
    }
  },
  typography: {
    fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
    heading: {
      primary: 'text-4xl md:text-5xl lg:text-6xl font-bold',
      secondary: 'text-3xl md:text-4xl font-semibold',
      subtitle: 'text-xl md:text-2xl font-medium'
    }
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(47, 79, 79, 0.05)',
    md: '0 4px 6px -1px rgba(47, 79, 79, 0.1), 0 2px 4px -1px rgba(47, 79, 79, 0.06)',
    lg: '0 10px 15px -3px rgba(47, 79, 79, 0.1), 0 4px 6px -2px rgba(47, 79, 79, 0.05)'
  },
  gradients: {
    primary: 'linear-gradient(to right, #2F4F4F, #3D6363)',
    secondary: 'linear-gradient(to right, #8B7355, #9C8366)'
  }
};
export const theme5 = {
  colors: {
    primary: '#7C3AED',    // Violet vif
    secondary: '#5B21B6',  // Violet foncé
    accent: '#4C1D95',     // Violet très foncé
    dark: '#0F172A',       // Bleu très foncé
    text: {
      primary: '#F8FAFC',   // Blanc cassé
      secondary: '#E2E8F0', // Gris clair
      light: '#CBD5E1'      // Gris moyen
    },
    background: {
      primary: '#0F172A',   // Bleu très foncé
      secondary: '#1E293B', // Bleu foncé
      tertiary: '#334155',  // Bleu-gris foncé
      card: '#1E293B',      // Bleu foncé
      overlay: 'rgba(15, 23, 42, 0.7)' // Bleu foncé transparent
    },
    border: {
      light: '#334155',     // Bleu-gris foncé
      dark: '#475569'       // Bleu-gris
    },
    hover: {
      primary: '#5B21B6',   // Violet foncé
      secondary: '#4C1D95', // Violet très foncé
      dark: '#3730A3'       // Indigo foncé
    }
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(15, 23, 42, 0.7)',
    md: '0 4px 6px -1px rgba(15, 23, 42, 0.7)',
    lg: '0 10px 15px -3px rgba(15, 23, 42, 0.7)'
  },
  gradients: {
    primary: 'linear-gradient(to right, #7C3AED, #5B21B6)',
    secondary: 'linear-gradient(to right, #5B21B6, #4C1D95)'
  }
};


export const theme = {
  colors: {
    primary: '#000000',    // Noir pur pour le texte principal
    secondary: '#4A4A4A',  // Gris foncé pour le texte secondaire
    accent: '#8B7355',     // Brun doux pour les accents
    dark: '#2F2F2F',       // Gris très foncé
    text: {
      primary: '#000000',   // Noir pour le texte principal
      secondary: '#4A4A4A', // Gris foncé pour le texte secondaire
      light: '#6E6E6E'      // Gris moyen pour le texte léger
    },
    background: {
      primary: '#FFFFFF',   // Blanc pur pour le fond principal
      secondary: '#FAFAFA', // Blanc cassé très subtil
      tertiary: '#F5F5F5',  // Gris très clair
      card: '#FFFFFF',      // Blanc pur pour les cartes
      overlay: 'rgba(0, 0, 0, 0.1)' // Overlay subtil
    },
    border: {
      light: '#EEEEEE',     // Gris très clair pour les bordures
      dark: '#E0E0E0'       // Gris clair pour les bordures foncées
    },
    hover: {
      primary: '#2F2F2F',   // Gris très foncé pour les hovers
      secondary: '#4A4A4A', // Gris foncé
      dark: '#000000'       // Noir
    }
  },
  typography: {
    fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
    heading: {
      primary: 'text-5xl md:text-6xl lg:text-7xl font-bold',     // Plus grand pour le titre principal
      secondary: 'text-3xl md:text-4xl font-semibold',
      subtitle: 'text-xl md:text-2xl font-medium text-gray-600'
    }
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)'
  },
  gradients: {
    primary: 'linear-gradient(to right, #000000, #2F2F2F)',
    secondary: 'linear-gradient(to right, #4A4A4A, #6E6E6E)'
  },
  spacing: {
    section: 'py-20 md:py-32', // Plus d'espace vertical pour les sections
    container: 'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
  },
  transitions: {
    default: 'all 0.3s ease-in-out',
    slow: 'all 0.5s ease-in-out'
  }
};