import projectImage from '../assets/project.png';

export const projectsData = [
    {
      imageUrl: projectImage,
      technologies: ["React", "TailwindCSS"],
      githubUrl: "https://github.com/elmrnidev",
      demoUrl: "",
      translations: {
        fr: {
          title: "Portfolio Personnel",
          description: "Un portfolio moderne développé avec React et Tailwind CSS."
        },
        en: {
          title: "Personal Portfolio",
          description: "A modern portfolio built with React and Tailwind CSS."
        }
      }
    },
    {
      imageUrl: projectImage,
      technologies: ["Node.js", "Express", "MongoDB"],
      githubUrl: "",
      demoUrl: "",
      translations: {
        fr: {
          title: "API REST",
          description: "Une API REST développée avec Node.js, Express et MongoDB."
        },
        en: {
          title: "REST API",
          description: "A REST API built with Node.js, Express and MongoDB."
        }
      }
      
    },
    {
      imageUrl: projectImage,
      technologies: ["Python", "Django"],
      githubUrl: "",
      demoUrl: "",
      translations: {
        fr: {
          title: "Blog",
          description: "Un blog développé avec Python et Django."
        },
        en: {
          title: "Blog",
          description: "A blog built with Python and Django."
        }
      }
    },
    {
      imageUrl: projectImage,
      technologies: ["React", "Firebase"],
      githubUrl: "",
      demoUrl: "",
      translations: {
        fr: {
          title: "Application Web",
          description: "Une application web développée avec React et Firebase."
        },
        en: {
          title: "Web Application",
          description: "A web application built with React and Firebase."
        }
      }
    },
    {
      imageUrl: projectImage,
      technologies: ["Vue.js", "Vuetify"],
      githubUrl: "",
      demoUrl: "",
      translations: {
        fr: {
          title: "Dashboard",
          description: "Un dashboard développé avec Vue.js et Vuetify."
        },
        en: {
          title: "Dashboard",
          description: "A dashboard built with Vue.js and Vuetify."
        }
      }
    }      
  ];

  export const projectsSection = {
    translations: {
      fr: {
        title: "Projets",
        subtitle: "Découvrez mes projets réalisés durant mes études, stages et missions clients"
      },
      en: {
        title: "Projects", 
        subtitle: "Discover my projects developed during my studies, internships and client work"
      }
    }
  };