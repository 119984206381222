import React from 'react';
import { Github, LinkedinIcon, Download, Mail } from 'lucide-react';
import { heroConfig } from './../data/personalData';
import { useTranslation } from '../contexts/LanguageContext';
import profileImage from '../assets/moon.jpeg';
import { theme } from './Theme';

const Hero = ({ 
  name = heroConfig.name,
  image = profileImage,
  social = heroConfig.social
}) => {
  
  const { translate } = useTranslation();
  const texts = translate(heroConfig);
  return (
    <section 
      className="min-h-screen flex items-center"
      style={{ 
        background: `linear-gradient(180deg, ${theme.colors.background.secondary}, ${theme.colors.background.primary})`
      }}
    >
      <div className="container mx-auto px-4 py-20">
        <div className="flex flex-col-reverse md:flex-row items-center justify-between gap-12">
          {/* Contenu */}
          <div className="md:w-1/2 space-y-8 text-center md:text-left">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight"
                  style={{ color: theme.colors.text.primary }}>
                {texts.greeting} <br/>
                <span style={{ color: theme.colors.primary }}>{name}</span>
              </h1>
              
              <h2 className="text-xl md:text-2xl font-medium"
                  style={{ color: theme.colors.text.secondary }}>
                {texts.title}
              </h2>
              
              <p className="text-lg"
                 style={{ color: theme.colors.text.light }}>
                {texts.description}
              </p>
            </div>
            
            <div className="flex flex-wrap gap-4 justify-center md:justify-start">
              <a href="/contact" 
                className="px-8 py-3 rounded-lg font-medium transition-all duration-300 flex items-center gap-2 hover:transform hover:scale-105"
                style={{ 
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.background.primary,
                  boxShadow: theme.shadows.sm,
                  ':hover': {
                    backgroundColor: theme.colors.hover.primary,
                    boxShadow: theme.shadows.md
                  }
                }}>
                <Mail size={20} />
                {texts.contact}
              </a>
              
              <a href="/cv.pdf" 
                className="px-8 py-3 rounded-lg font-medium transition-all duration-300 flex items-center gap-2 hover:transform hover:scale-105"
                style={{ 
                  border: `2px solid ${theme.colors.primary}`,
                  color: theme.colors.primary,
                  ':hover': {
                    backgroundColor: theme.colors.background.secondary,
                    boxShadow: theme.shadows.sm
                  }
                }}>
                <Download size={20} />
                {texts.downloadCV}
              </a>
            </div>

            {/* Liens sociaux */}
            <div className="flex gap-6 justify-center md:justify-start pt-4">
              {Object.entries(social).map(([platform, url]) => (
                <a key={platform}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-transform duration-300 hover:scale-110"
                  style={{ 
                    color: theme.colors.text.secondary,
                    ':hover': { color: theme.colors.primary }
                  }}>
                  {platform === 'github' ? <Github size={28} /> : <LinkedinIcon size={28} />}
                </a>
              ))}
            </div>
          </div>

          {/* Image */}
          <div className="md:w-1/2 flex justify-center">
            <div className="relative w-64 h-64 md:w-96 md:h-96">
              <div 
                className="absolute inset-0 rounded-full blur-3xl"
                style={{ 
                  backgroundColor: theme.colors.accent,
                  opacity: 0.3 
                }}
              />
              <img
                src={image}
                alt={name}
                className="rounded-full object-cover relative z-10"
                style={{ 
                  boxShadow: theme.shadows.lg,
                  border: `4px solid ${theme.colors.background.primary}`
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;