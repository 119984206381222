import { Code, Terminal, Cloud, Network, Monitor } from 'lucide-react';

export const skillCategories = [
    {
      icon: <Code className="" size={24} />,
      translations: {
        fr: {
          title: "Backend Development",
          description: "Construction d'APIs et de services backend"
        },
        en: {
          title: "Backend Development",
          description: "Building APIs and backend services"
        }
      },
      skills: [
        {
          name: "Python",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
          level: "Intermediate",
          translations: {
            fr: { experience: "1 an" },
            en: { experience: "1 year" }
          }
        },
        {
          name: "Django",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg",
          level: "Basic",
          translations: {
            fr: { experience: "6 mois" },
            en: { experience: "6 months" }
          }
        },
        {
          name: "Java",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg",
          level: "Intermediate",
          translations: {
            fr: { experience: "2 an" },
            en: { experience: "2 year" }
          }
        },
        {
          name: "Spring Boot",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "6 mois" },
            en: { experience: "6 months" }
          }
        },

        {
          name: "C++",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "1 an" },
            en: { experience: "1 year" }
          }
        }
      ]
    },
    {
      icon: <Cloud className="" size={24} />,
      translations: {
        fr: {
          title: "Cloud & DevOps",
          description: "Expérience en déploiement et automatisation cloud"
        },
        en: {
          title: "Cloud & DevOps",
          description: "Experience in cloud deployment and automation"
        }
      },
      skills: [
        {
          name: "Terraform",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/terraform/terraform-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "6 mois" },
            en: { experience: "6 months" }
          }
        },
        {
          name: "Docker",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "6 mois" },
            en: { experience: "6 months" }
          }
        },
        {
          name: "VMware ESXi",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vmware/vmware-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "6 mois" },
            en: { experience: "6 months" }
          }
        }
      ]
    },
    {
      icon: <Network className="" size={24} />,
      translations: {
        fr: {
          title: "System & Network",
          description: "Configuration et maintenance des systèmes et réseaux"
        },
        en: {
          title: "System & Network",
          description: "System and network configuration and maintenance"
        }
      },
      skills: [
        {
          name: "Linux",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg",
          level: "Intermediate",
          translations: {
            fr: { experience: "2 ans" },
            en: { experience: "2 years" }
          }
        },
        {
          name: "Cisco",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/networkx/networkx-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "2 an" },
            en: { experience: "2 year" }
          }
        },
        {
          name: "LibreNMS",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/grafana/grafana-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "6 mois" },
            en: { experience: "6 months" }
          }
        }
      ]
    },
    {
      icon: <Terminal className="" size={24} />,
      translations: {
        fr: {
          title: "Development Tools",
          description: "Utilisation d'outils de développement essentiels"
        },
        en: {
          title: "Development Tools",
          description: "Using essential development tools"
        }
      },
      skills: [
        {
          name: "Git",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
          level: "Intermediate",
          translations: {
            fr: { experience: "2 ans" },
            en: { experience: "2 years" }
          }
        },
        {
          name: "MySQL",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "1 an" },
            en: { experience: "1 year" }
          }
        },
        {
          name: "Bash",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "1 an" },
            en: { experience: "1 year" }
          }
        }
      ]
    },
    {
      icon: <Monitor className="" size={24} />,
      translations: {
        fr: {
          title: "Web Development",
          description: "Développement et intégration web"
        },
        en: {
          title: "Web Development",
          description: "Web development and integration"
        }
      },
      skills: [
        {
          name: "HTML/CSS",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
          level: "Intermediate",
          translations: {
            fr: { experience: "2 ans" },
            en: { experience: "2 years" }
          }
        },
        {
          name: "JavaScript",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "1 an" },
            en: { experience: "1 year" }
          }
        },
        {
          name: "Angular",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "6 mois" },
            en: { experience: "6 months" }
          }
        },
        {
          name: "React",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
          level: "Basic",
          translations: {
            fr: { experience: "1 mois" },
            en: { experience: "1 months" }
          }
        }
        ,
        {
          name: "WordPress",
          logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-plain.svg",
          level: "Intermediate",
          translations: {
            fr: { experience: "" },
            en: { experience: "" }
          }
        }
      ]
    }
];

// Traductions pour le titre de la section
export const skillsSection = {
  translations: {
    fr: {
      title: "Mes compétences",
      subtitle: "Découvrez les langages et outils avec lesquels je travaille"
    },
    en: {
      title: "My Skills",
      subtitle: "Discover the languages and tools I work with"
    }
  }
};